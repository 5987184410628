/* You can add global styles to this file, and also import other style files */

/* You can add global styles to this file, and also import other style files */

@import "~trumbowyg/dist/ui/trumbowyg.min.css";
@import "~./../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";
.mat-ink-bar.mat-ink-bar {
  background-color: #e87624 !important;
}

.mat-elevation-z4 {
  box-shadow: 0px 0px 0px 0px;
}

.mat-tab-header-pagination-chevron {
  border-color: #e87624 !important;
}

.pad-0 {
  padding: 0px !important;
}
.error {
  color: #dc3545 !important;
  font-weight: bold;
}

.tour {
  padding: 10px;
  background-color: #123e7b;
  position: relative;
  color: #fff;
}
/* .touranchor--is-active{
  background: #123e7b;
  color: #fff;
}
.touranchor--is-active .mat-form-field-underline{
  background-color: #fff;
} */

.tour .ngxp-title {
  font-weight: 600;
  font-size: 15px;
}

.tour .ngxp-content {
  font-size: 12px;
}

.tour .tour-step-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tour .tour-step-navigation button {
  background-color: white;
  border: 0;
  color: #000;
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 10px;
}

.btn.disabled,
.btn:disabled {
  cursor: not-allowed;
}

/* custom class for slider tab background */

.white-background {
  background-color: white;
}

.margin-0 {
  margin: 0px;
}

.col {
  padding-left: 10px;
  padding-right: 10px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-20 {
  margin-top: 20px;
}

.modal-dialog-centered {
  height: calc(100% - (1.75rem * 2));
}

.editButton {
  color: #ea7926 !important;
  cursor: pointer;
}

.editButton:hover {
  color: #fff !important;
  cursor: pointer;
}

.theme-color {
  color: #ea7926 !important;
}

.pointer {
  cursor: pointer;
}

.pad-10 {
  padding: 10px;
}

.font-bold {
  font-weight: bold;
  color: black !important;
}

.mar-l-20 {
  margin-left: 20px;
}

.readonly-attr {
  font-family: "Gotham-Medium";
  font-size: 13px;
  color: #333333;
  /* padding-left: 5px; */
}

.readonly-attr-icon {
  color: #e87624;
  position: relative;
  top: 0px;
  margin-left: 10px;
  display: none;
}

.pHeading {
  font-weight: bold !important;
  color: #ea7926 !important;
  font-size: 12px !important;
  padding: 5px 0px !important;
}

.p-l-12 {
  padding-left: 12px;
}

.p-l-5 {
  padding-left: 5px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-r-20 {
  padding-right: 20px;
}

.outline-none {
  outline: none !important;
}

.p-10 {
  padding: 10px;
}

.p-t-0 {
  padding-top: 0px !important;
}

.p-t-10 {
  padding-top: 10px;
}

.p-t-12 {
  padding-top: 12px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-t-b-10 {
  padding: 10px 0;
}

.m-10 {
  margin: 10px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.f-lg {
  font-size: 15px;
}

.custom-font {
  font-size: 0.857rem;
  font-family: Gotham-Book;
  margin: 0px;
}

.address-form .mat-form-field-underline {
  display: none !important;
}

.text-center {
  text-align: center;
}

.float-right {
  float: right;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-20 {
  padding: 20px;
}

.text-underline {
  text-decoration: underline;
}

/*Voice section CSS starts here*/

voice-container .nav-tabs .nav-item.show .nav-link:active,
.voice-container .nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: transparent !important;
  border-color: transparent !important;
  border-bottom: 1px solid #e87624 !important;
}

.voice-container .nav-tabs {
  border-bottom: none !important;
  margin-left: -30px;
}

.voice-container .nav-tabs .nav-item .nav-link span {
  color: #fff !important;
}

.voice-container .nav-tabs .nav-link:not(.disabled):focus,
.voice-container .nav-tabs .nav-link:not(.disabled):hover {
  border-color: transparent !important;
  border-bottom: 1px solid #e87624 !important;
}

.voice-container .nav-tabs .nav-link.disabled span {
  color: gray !important;
}

.customVoiceContainer .card {
  background-color: transparent !important;
  border: none !important;
}

.customVoiceContainer .card-body {
  background-color: rgba(255, 255, 255, 0) !important;
  padding: 0;
}

.customVoiceContainer .price-summary {
  margin-top: 0 !important;
}

.customVoiceContainer .justify-content-between {
  border-bottom: none !important;
  padding-bottom: 0 !important;
}

.customVoiceContainer .card-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0) !important;
  padding: 0;
}

.customVoiceContainer .accordion-toggle {
  margin-top: 21px;
}

.customVoiceContainer .margin-clss {
  margin-top: 10px !important;
}

.voiceService-quoteSummary .card-body {
  min-height: 100px !important;
}

.voice-reviewAccept .tab-content {
  height: auto !important;
  overflow: hidden;
}

.sol-launch-del ul li:hover {
  background: none !important;
  color: #333333 !important;
}
.sol-launch-del ul li:hover [class*="col-"]:last-child {
  background: none !important;
  color: #333333 !important;
}

.voice-container #service-desc-tab,
.voice-container #service-desc-tab,
.voice-container #service-desc-tab {
  color: #333333 !important;
}

.voiceServices-selectConfiguration .popover-arrow {
  display: none !important;
}

.voiceServices-selectConfiguration
  .popover.in.popover-right.bs-popover-right.right.show,
.voiceServices-selectConfiguration
  .popover.in.popover-left.bs-popover-left.left.show {
  top: 79px !important;
  left: 49px !important;
  max-width: 324px !important;
  width: 400px !important;
  transform: none !important;
}

/*Voice section CSS ends here*/

@media (max-width: 450px) {
  .scrollClass {
    overflow-x: hidden;
  }

  .pac-item {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 15px !important;
  }

  .hdpi.pac-logo:after {
    background-image: none !important;
  }

  .pac-container {
    box-shadow: none;
    border-top: none;
  }

  main {
    overflow-x: hidden;
  }

  .tcl-loader-modal .modal-content {
    background-color: transparent;
  }

  main {
    min-height: calc(100vh - 93px);
  }

  .togglemain {
    margin-left: auto;
  }

  .sidenavbar {
    top: 3rem;
    z-index: 4000;
    height: 100%;
  }

  .navbar {
    max-height: 50px;
    min-height: 50px;
  }

  .primary-navigation a.nav-link {
    padding: 7px 14px 7px 14px;
  }

  footer {
    margin-top: auto;
  }

  .services-content .expand-panel span.icon {
    position: absolute;
    top: -25px;
    color: #ffffff;
    left: 10px;
    width: 35px;
    height: 35px;
    background: #e87624;
    border-radius: 50%;
    text-align: center;
    font-size: 18px;
    line-height: 2.5rem;
  }
}

.newGstClass {
  width: 80%;
  border: none;
  border-bottom: 1px solid #d8d8d8;
  font-weight: normal !important;
  font-family: "Gotham-Book" !important;
  font-size: 12px;
}

.billingEdit {
  float: right;
  color: #ea7926;
  font-size: 1.2rem;
  margin-right: 5px;
  margin-top: 12px;
  cursor: pointer;
}

.addressEdit {
  margin-top: 0px !important;
  padding-right: 12px !important;
}

.userEdit {
  font-weight: 600;
  font-size: 12px;
}

.voice-container .popover {
  display: block;
  top: 128px;
  left: 92px;
  width: 250px;
  height: 180px;
  overflow-y: scroll;
}
.voice-container .select .popover {
  width: auto !important;
  height: auto !important;
  overflow-y: unset !important;
}

.modal-open {
  padding-right: 0px !important;
}

.legatentityClass {
  width: 100% !important;
  border: none !important;
  cursor: pointer;
}

.legatentityClass:disabled {
  background-color: rgb(255, 255, 255);
  opacity: 0.7;
  cursor: auto;
}

/* .custom-tabs .nav-tabs .nav-link.active, .nav-link {
    color:#333333 !important
} */

/**Support & Manitaneance**/

.mat-menu-panel {
  position: fixed;
}

/* color cards */

/* filter cards styles */

.filter-card {
  color: #333333;
  font-size: 12px;
  /* font-family: Roboto, "Helvetica Neue", sans-serif; */
  text-align: center;
  max-width: 200px !important;
  padding: 8px;
  margin-bottom: 1%;
  margin-left: 15px;
  font-weight: 300;
}

.statusCard {
  color: black;
  font-size: 12px;
  /* font-family: Roboto, "Helvetica Neue", sans-serif; */
  text-align: center;
  max-width: 135px !important;
  border: 1px solid #e2e2e2;
  padding: 8px;
  box-shadow: 0px 1px 1px 1px #e2e2e2;
  margin-bottom: 1%;
  margin-left: 15px;
  font-weight: 500;
  background: white;
}

.bl-orange {
  border-left: 5px solid #ea7926 !important;
}

.bl-yellow {
  border-left: 5px solid #fbcc0a !important;
}

.bl-red {
  border-left: 5px solid #e1243c !important;
}

.bl-blue {
  border-left: 5px solid #3d86c6 !important;
}

.bl-green {
  border-left: 5px solid #a8cf4e !important;
}

.bl-dark-green {
  border-left: 5px solid #007e33 !important;
}

.bl-grey {
  border-left: 5px solid #bdbdbd !important;
}

.bl-dark-blue {
  border-left: 5px solid #00445e !important;
}

.text-grey {
  color: #bdbdbd;
}

.text-green {
  color: #a8cf4e;
}

.text-yellow {
  color: #fbcc0a !important;
}

.text-orange {
  color: #ea7926 !important;
}

.text-red {
  color: #e1243c !important;
}

.text-blue {
  color: #3d86c6;
}

.text-black {
  color: #333333;
}
.text-white {
  color: #dddddd !important;
}
/* color cards style end */

/*
   *  Scroll Bar
   */

#custom-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #f5f5f5 !important;
}

#custom-scroll::-webkit-scrollbar {
  height: 6px !important;
  width: 10px !important;
  background-color: #f5f5f5 !important;
}

#custom-scroll::-webkit-scrollbar-thumb {
  background-color: #ea7926 !important;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  ) !important;
}

/* no margin */

.no-margin {
  margin-left: 0px;
  margin-right: 0px;
}

/** Dialog */

.mat-dialog-title {
  margin-bottom: 18px !important;
  margin-top: -22px !important;
  margin-left: -22px !important;
  margin-right: -22px !important;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  padding-left: 10px;
  height: 30px;
  background: #dddddd;
}

.mat-dialog-content {
  font-size: 14px;
  font-weight: bold;
  /* text-align: center; */
}

/* .mat-dialog-container {
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
    display: block;
    padding: 24px;
    border-radius: 2px;
    box-sizing: border-box;
    overflow: auto;
    outline: 0;
    width: 100%;
    height: 100%;
} */

.mat-dialog-content {
  display: block;
  margin: 0 -24px;
  padding: 0 24px;
  min-height: 8vh;
  max-height: 83vh !important;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.mat-dialog-actions[align="center"] {
  justify-content: center;
}

.mat-dialog-actions {
  padding: 12px 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -24px;
}

/* dialog styles end */

/* datepicker styles */

/*
  .mat-calendar-header {
    padding: 0;
  } */

.mat-calendar-header {
  padding: 29px 8px 0 8px !important;
}

.mat-calendar-content {
  padding: 0px 9px 8px 8px !important;
  outline: 0;
}

/**Ends**/

.center-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

/**New Billing Ticket Related styles **/

.createBillingTab {
  width: 50%;
}

.white-arrow {
  color: white;
  cursor: pointer;
}

.quoteContent {
  color: red !important;
  position: absolute !important;
  margin-top: -6px !important;
  /* float: left; */
  margin-left: -11px !important;
  font-size: 25px !important;
}

.mat-picker-button button {
  line-height: 0;
  height: auto;
}

/* .disabled{
    pointer-events: none;
    opacity: 0.6;
} */

.disable-div {
  pointer-events: none !important;
  opacity: 0.6 !important;
}

/**Admin Task List related works **/
.admin-task-list .nav-item .active {
  background: initial;
  border: 0px;
  /* border-bottom: 4px solid #ea7926; */
  background: #ea7926;
}

.admin-task-list .nav-item .nav-link {
  color: white;
}

.admin-task-list .nav-tabs .nav-link:hover {
  border-color: transparent;
  border-bottom: 1px solid #ea7926;
  /* padding: 10px 40px; */
}

.admin-task-list .nav-tabs .nav-item {
  min-width: 150px;
  text-align: center;
  /* background: #ea7926; */
}

.multiselect .dropdown-list {
  min-width: 200px;
}

.orders-list .nav-item .active {
  background: initial;
  border: 0px;
  border-bottom: 4px solid #ea7926;
}

.orders-list .nav-item .nav-link {
  color: black;
}

.orders-list .nav-tabs .nav-link:hover {
  border-color: transparent;
  border-bottom: 4px solid #ea7926;
}

.custom-conduct-survey-task .mat-tab-body-content {
  overflow: initial;
}

.admin-task-dashboard .mat-card-header-text {
  margin: 0px;
}

.admin-task-dashboard .nav-tabs {
  margin-bottom: 0px;
  padding-left: 0px;
  border-bottom: 0px;
}

/**Ends Here **/

.margin-auto {
  margin: auto;
}

.order-list .c-btn {
  padding: 0 !important;
  border: none !important;
  align-items: center;
}

.order-list .selected-list .c-angle-down,
.order-list .selected-list .c-angle-up {
  width: 11px !important;
  height: 21px !important;
}

.order-list .pure-checkbox input[type="checkbox"] + label:before {
  border: 2px solid #ea7926 !important;
  color: #ea7926 !important;
}

.order-list .pure-checkbox input[type="checkbox"]:checked + label:before {
  border: 2px solid #ea7926 !important;
  color: #ea7926 !important;
}

.order-list .pure-checkbox input[type="checkbox"]:checked + label:before {
  background: #ea7926 !important;
}

.order-list .pure-checkbox input[type="checkbox"] + label {
  font-size: 13px !important;
  padding-left: 2.4em !important;
}

.order-list .selected-list .c-list .c-token .c-remove {
  width: 8px !important;
  top: 44% !important;
}

.order-list .selected-list .c-list .c-token {
  background: #ea7926 !important;
  font-size: 11px !important;
}

.order-list .selected-list .c-angle-down svg,
.order-list .selected-list .c-angle-up svg {
  fill: #ea7926 !important;
}

.order-list .dropdown-list[_ngcontent-c8] {
  width: 210% !important;
}

.site-address {
  font-size: 10px;
  color: black !important;
}

.fileupload_tick {
  color: green !important;
}

.importantClass {
  color: red;
  font-size: 19px;
}

.f-s-14 {
  font-size: 14px !important;
}

.f-w-b {
  font-weight: bold !important;
}

.f-s-19 {
  font-size: 19px !important;
}

/* Mat checkbox styles */

mat-checkbox.partial-checkbox
  > .mat-checkbox-layout
  > div.mat-checkbox-inner-container-no-side-margin {
  margin-left: 3px !important;
  margin-right: 5px !important;
  margin-top: 4px;
}

.mat-checkbox .mat-checkbox-frame {
  border-color: #ea7926;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate,
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate {
  background-color: #ea7926 !important;
}

.mat-checkbox-inner-container {
  height: 13px !important;
  width: 13px !important;
}

.relative-position {
  position: relative;
}

.top-change {
  margin-top: 25%;
}

.back-arrow {
  color: white !important;
}

.p-r-l-15 {
  padding: 0px 15px;
}

/* View Task Details component related work */

.view-task-details .mat-tab-label-content {
  font-size: 12px;
  font-family: "Gotham-Medium";
}

.justify-content-center {
  justify-content: center;
}

.ias-select-configuration .bs-popover-auto .arrow,
.bs-popover-right .arrow {
  display: none !important;
}
.user-list-container .table-div-card popover-container .popover-body {
  width: 100px !important;
}

.user-list-container
  .application-name-display
  popover-container
  .popover-arrow.arrow {
  display: none !important;
}

.user-list-container .application-name-display popover-container {
  background: #0a254a;
}

.user-list-container
  .application-name-display
  popover-container
  .popover-content.popover-body {
  color: white !important;
}

.user-list-container .application-name-display popover-container .popover-body {
  width: inherit !important;
  min-width: 100px;
}

.roles-container
  mat-card-title
  mat-form-field
  mat-focused
  .mat-form-field-label,
.modules-container
  mat-card-title
  mat-form-field
  mat-focused
  .mat-form-field-label,
.applications-container
  mat-card-title
  mat-form-field
  mat-focused
  .mat-form-field-label {
  /*change color of label*/
  color: #ea7926 !important;
}

.roles-container mat-card-title mat-form-field .mat-form-field-underline,
.modules-container
  mat-card-title
  mat-form-field
  mat-focused
  .mat-form-field-label,
.applications-container
  mat-card-title
  mat-form-field
  mat-focused
  .mat-form-field-label {
  /*change color of underline*/
  background-color: #ea7926 !important;
}

.roles-container mat-card-title mat-form-field .mat-form-field-ripple,
.modules-container
  mat-card-title
  mat-form-field
  mat-focused
  .mat-form-field-label,
.applications-container
  mat-card-title
  mat-form-field
  mat-focused
  .mat-form-field-label {
  /*change color of underline when focused*/
  background-color: #ea7926 !important;
}

.custom-search-multi-dropdown .selected-list .c-list {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.custom-search-multi-dropdown .selected-list .c-list.countplaceholder {
  right: 51px;
}

/* .lazyContainer li label{
        width: 250px;
    } */

.custom-search-multi .pure-checkbox :nth-child(4n) {
  width: 300px !important;
}

/* .add-user.customer-single-dropdown angular2-multiselect .selected-list.c-btn.c-angle-down,
    .add-user.customer-single-dropdown angular2-multiselect .selected-list.c-btn.c-angle-up{

        display: none!important;

    } */

.custom-search-multi
  > angular2-multiselect
  > .cuppa-dropdown
  > .selected-list
  > .c-btn.form-control {
  border: unset;
  border-bottom: 1px solid #cdcdcd !important;
  margin-top: -10px !important;
  width: 250px !important;
}

.view-task-details .get-quote-container .card-body {
  min-height: 0px !important;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.overflow-scroll {
  overflow: scroll;
}

.admin-task-list mat-card-header .tab-container ul {
  margin-bottom: -1px !important;
  padding-left: 0px !important;
}
.admin-task-list mat-card-header .mat-card-header-text {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
/* .main-tab tabset ul  {
    padding:0px;
    } */

.different-tab .mat-card-header-text {
  margin: 0px;
  margin-left: 0px;
}
.main-tab .nav-tabs {
  border-bottom: none;
  padding-left: 0px;
}

.service-dashboard .c-token {
  font-size: 7px;
  max-width: 150px;
}

.task-list .c-token {
  font-size: 8px;
}

.validate-document .headerClass {
  font-size: 14px;
  font-family: gotham-medium;
}
.validate-document .parentClass {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  margin-top: 15px;
}
.validate-document .parentClass div.card-header {
  background: #e9eaea;
  min-height: 0px !important;
}

.validate-document .parentClass .card-body {
  max-height: 200px !important;
  height: 200px !important;
}
.task-container .container {
  width: 95% !important;
  max-width: 95% !important;
}

.lmProvider .popover {
  max-width: 100% !important;
  width: 90% !important;
}

.lmProvider .popover-body,
.taskPopOver .popover-body {
  padding: 0px;
}

.mast-installation-plan-list {
  overflow: scorll !important;
}

.mast-installation-plan {
  left: -120% !important;
}

.onHover:hover {
  cursor: pointer;
  text-decoration: underline;
}

/* .task-container .container {
    width: 80% !important;
    max-width: 80% !important;
} */

.full-width {
  width: 100%;
}

/*.voice-container .caveats popover-container{
    !*top: -45px !important;*!
    top: 45px !important;
}*/
.select-addons-modal {
  max-width: 750px !important;
}
.select-addons-modal .quote-list {
  max-height: 455px;
  overflow-y: auto;
  /* overflow: visible; */
}
.select-addons-modal .mat-dialog-container {
  padding: 0 !important;
  overflow: visible;
}

.select-profile-modal {
  max-width: 300px !important;
}

.feature-list .modal-title {
  flex: 0 0 95%;
}

.product-primary-color-custom-toggle.mat-slide-toggle.mat-checked:not(
    .mat-disabled
  )
  .mat-slide-toggle-thumb {
  background-color: #ea7926 !important;
}

.product-primary-color-custom-toggle.mat-slide-toggle.mat-checked:not(
    .mat-disabled
  )
  .mat-slide-toggle-bar {
  background-color: rgb(236 163 109) !important;
}

.white-color-custom-mat-select.mat-form-field-appearance-legacy
  .mat-form-field-underline {
  background-color: white !important;
}
.white-color-custom-mat-select.mat-form-field-appearance-legacy
  .mat-select-arrow,
.white-color-custom-mat-select.mat-form-field-appearance-legacy
  .mat-select-value,
.white-color-custom-mat-select.mat-form-field-appearance-legacy
  .mat-form-field-prefix
  span {
  color: white !important;
}

.product-primary-color-custom-focused-mat-select.mat-focused.mat-form-field-appearance-legacy
  .mat-form-field-underline,
.product-primary-color-custom-focused-mat-select.mat-focused.mat-form-field
  .mat-form-field-ripple {
  background-color: #ea7926 !important;
}

.product-primary-color-custom-focused-mat-select.mat-focused.mat-form-field
  .mat-select-arrow {
  color: #ea7926 !important;
}

/* .fade.show {
    opacity: 1;
    z-index: 111111;
} */

/* BYOC specific styles */
.byoc-label {
  background: #ea7926;
  color: white;
  padding: 4px;
  border-radius: 4px;
  margin-right: 8px;
}

.mat-select-arrow {
  color: #ea7926 !important;
}

.warning-snackbar {
  display: inline-block !important;
  color: #000 !important;
  background-color: #ffeb3b !important;
  font-weight: bold;
  position: fixed;
  top: 20%;
  left: 40%;
  text-align: center;
  margin: auto;
}

.success-snackbar {
  color: #000 !important;
  background-color: greenyellow !important;
  font-weight: bold;
  display: inline-block !important;
  position: fixed;
  top: 20%;
  left: 40%;
  text-align: center;
  margin: auto;
}

.danger-snackbar {
  color: #000 !important;
  background-color: red !important;
  font-weight: bold;
  display: inline-block !important;
  position: fixed;
  top: 20%;
  left: 40%;
  text-align: center;
  margin: auto;
}
.mCustomScrollbar {
  overflow: auto;
  overflow-x: hidden;
}
.mCustomScrollbar::-webkit-scrollbar {
  width: 3px;
}
.mCustomScrollbar::-webkit-scrollbar-thumb {
  background: #e87624;
}
/** Izo-ne */
.w50 {
  display: inline-block;
  width: 50%;
}
.w33 {
  display: inline-block;
  width: 33%;
}
.w77 {
  display: inline-block;
  width: 77%;
}
.w100 {
  display: inline-block;
  width: 100%;
}

.w60 {
  display: inline-block;
  width: 60%;
}

.w40 {
  display: inline-block;
  width: 40%;
}
.w10 {
  display: inline-block;
  width: 10%;
}

.vert-align-mid {
  vertical-align: middle;
}

.izone-review-config .mat-expansion-panel-content {
  max-height: 200px;
  visibility: visible;
  overflow: auto;
}

.edit-config .mat-form-field {
  width: 50% !important;
}

.select-config .mat-form-field {
  width: 52% !important;
}

.panelList .mat-form-field {
  width: 92% !important;
}

/* You can add global styles to this file, and also import other style files */
.gm-style-cc {
  display: none !important;
}
/* .gmnoprint{
    position: absolute;
    left: 0px;
    top: 45px;
} */
.gm-bundled-control,
.gm-bundled-control-on-bottom {
  margin: 10px;
  user-select: none;
  position: absolute;
  bottom: 213px;
  right: 40px;
}
/* gmnoprint gm-bundled-control gm-bundled-control-on-bottom */
.gmnoprint {
  position: absolute !important;
  right: 48px !important;
  bottom: 205px !important;
}
